import React from "react";
import { Link } from "react-router-dom";

export default function CTASimple() {
    return (
        <>
            {/* CTA Section: Simple */}
            <div className="bg-white dark:bg-gray-800 dark:text-gray-100">
                <div className="container mx-auto space-y-8 px-4 py-16 lg:px-8 lg:py-32 xl:max-w-7xl">
                    {/* Heading */}
                    <div className="text-center">
                        <h2 className="mb-4 text-4xl font-black text-black dark:text-white">
                            Are you ready to start creating? <span className="text-emerald-600 dark:text-emerald-500">Let’s do it!</span>
                        </h2>
                        <h3 className="mx-auto text-xl font-medium leading-relaxed text-gray-700 lg:w-2/3 dark:text-gray-300">
                            Inspiring results from day one without the pain. Get your own
                            custom dashboard and start building amazing services.
                        </h3>
                    </div>
                    {/* END Heading */}

                    {/* Actions */}
                    <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-center sm:gap-3">
                        <Link to="/signup"
                            className="inline-flex items-center justify-center gap-2 rounded-lg border border-emerald-700 bg-emerald-700 px-6 py-3 font-semibold leading-6 text-white hover:border-emerald-600 hover:bg-emerald-600 hover:text-white focus:ring focus:ring-emerald-400/50 active:border-emerald-700 active:bg-emerald-700 dark:focus:ring-emerald-400/90"
                        >
                            <span>Get Started</span>
                        </Link>
                    </div>
                    {/* END Actions */}
                </div>
            </div>
            {/* END CTA Section: Simple */}
        </>
    );
}

import React from "react";

export default function TeamCirclePhotoswithTitle() {
    return (
        <>
            {/* Team Section: Circle Photos with Title */}
            <div className="bg-white dark:bg-gray-900 dark:text-gray-100">
                <div className="container mx-auto space-y-16 px-4 py-16 lg:px-8 lg:pt-8 lg:pb-32 xl:max-w-7xl">
                    {/* Heading */}
                    <div className="text-center">
                        <div className="mb-1 text-sm font-bold uppercase tracking-wider text-emerald-600 dark:text-emerald-500">
                            Real People
                        </div>
                        <h2 className="mb-4 text-4xl font-black text-black dark:text-white">
                            Meet Our Talented Team
                        </h2>
                        <h3 className="mx-auto text-xl font-medium leading-relaxed text-gray-700 lg:w-2/3 dark:text-gray-300">
                            Our dedicated team of garden architects and designers work tirelessly behind the scenes to bring your dream garden to life.
                        </h3>
                    </div>
                    {/* END Heading */}

                    {/* Team */}
                    <div className="grid grid-cols-1 gap-8 text-center sm:grid-cols-2 md:grid-cols-3 md:gap-16">
                        <div>
              <span className="mb-5 inline-block rounded-full bg-white p-2 shadow-lg dark:bg-gray-700/75 dark:shadow-gray-950">
                <img
                    src="https://cdn.tailkit.com/media/placeholders/avatar-bY4GXQKfZrA-320x320.jpg"
                    alt="Irma Norton"
                    className="inline-block size-28 rounded-full"
                />
              </span>
                            <h4 className="mb-0.5 text-xl font-bold">Irma Norton</h4>
                            <p className="font-medium text-gray-600 dark:text-gray-400">
                                Founder &amp; CEO
                            </p>
                        </div>
                        <div>
              <span className="mb-5 inline-block rounded-full bg-white p-2 shadow-lg dark:bg-gray-700/75 dark:shadow-gray-950">
                <img
                    src="https://cdn.tailkit.com/media/placeholders/avatar-iFgRcqHznqg-320x320.jpg"
                    alt="Alejandro Lee"
                    className="inline-block size-28 rounded-full"
                />
              </span>
                            <h4 className="mb-0.5 text-xl font-bold">Alejandro Lee</h4>
                            <p className="font-medium text-gray-600 dark:text-gray-400">
                                Lead Designer
                            </p>
                        </div>
                        <div>
              <span className="mb-5 inline-block rounded-full bg-white p-2 shadow-lg dark:bg-gray-700/75 dark:shadow-gray-950">
                <img
                    src="https://cdn.tailkit.com/media/placeholders/avatar-mEZ3PoFGs_k-320x320.jpg"
                    alt="Elsa King"
                    className="inline-block size-28 rounded-full"
                />
              </span>
                            <h4 className="mb-0.5 text-xl font-bold">Elsa King</h4>
                            <p className="font-medium text-gray-600 dark:text-gray-400">
                                Landscape Architect
                            </p>
                        </div>
                        <div>
              <span className="mb-5 inline-block rounded-full bg-white p-2 shadow-lg dark:bg-gray-700/75 dark:shadow-gray-950">
                <img
                    src="https://cdn.tailkit.com/media/placeholders/avatar-sibVwORYqs0-320x320.jpg"
                    alt="Alex Saunders"
                    className="inline-block size-28 rounded-full"
                />
              </span>
                            <h4 className="mb-0.5 text-xl font-bold">Alex Saunders</h4>
                            <p className="font-medium text-gray-600 dark:text-gray-400">
                                Horticulture Specialist
                            </p>
                        </div>
                        <div>
              <span className="mb-5 inline-block rounded-full bg-white p-2 shadow-lg dark:bg-gray-700/75 dark:shadow-gray-950">
                <img
                    src="https://cdn.tailkit.com/media/placeholders/avatar-euZ2n8dGUcQ-320x320.jpg"
                    alt="Herman Reese"
                    className="inline-block size-28 rounded-full"
                />
              </span>
                            <h4 className="mb-0.5 text-xl font-bold">Herman Reese</h4>
                            <p className="font-medium text-gray-600 dark:text-gray-400">
                                Support Specialist
                            </p>
                        </div>
                        <div>
              <span className="mb-5 inline-block rounded-full bg-white p-2 shadow-lg dark:bg-gray-700/75 dark:shadow-gray-950">
                <img
                    src="https://cdn.tailkit.com/media/placeholders/avatar-DLKR_x3T_7s-320x320.jpg"
                    alt="Sue Keller"
                    className="inline-block size-28 rounded-full"
                />
              </span>
                            <h4 className="mb-0.5 text-xl font-bold">Sue Keller</h4>
                            <p className="font-medium text-gray-600 dark:text-gray-400">
                                Garden Planner
                            </p>
                        </div>
                    </div>
                    {/* END Team */}
                </div>
            </div>
            {/* END Team Section: Circle Photos with Title */}
        </>
    );
}

import { Link, useNavigate } from "react-router-dom";
import React from "react";

const VerifyEmail = () => {
    const navigate = useNavigate();

    return (
        <>
            {/* Pages: Sign Up: Boxed */}

            {/* Page Container */}
            <div
                id="page-container"
                className="mx-auto flex min-h-dvh w-full min-w-[320px] flex-col bg-gray-100 dark:bg-gray-900 dark:text-gray-100"
            >
                {/* Page Content */}
                <main id="page-content" className="flex max-w-full flex-auto flex-col">
                    <div className="relative mx-auto flex min-h-dvh w-full max-w-10xl items-center justify-center overflow-hidden p-4 lg:p-8">
                        {/* Sign Up Section */}
                        <section className="w-full max-w-xl py-6">
                            {/* Header */}
                            <header className="mb-10 text-center">
                                <h1 className="mb-2 inline-flex items-center gap-2 text-2xl font-bold">
                                    <svg
                                        className="hi-mini hi-cube-transparent inline-block size-5 text-emerald-600 dark:text-emerald-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9.638 1.093a.75.75 0 01.724 0l2 1.104a.75.75 0 11-.724 1.313L10 2.607l-1.638.903a.75.75 0 11-.724-1.313l2-1.104zM5.403 4.287a.75.75 0 01-.295 1.019l-.805.444.805.444a.75.75 0 01-.724 1.314L3.5 7.02v.73a.75.75 0 01-1.5 0v-2a.75.75 0 01.388-.657l1.996-1.1a.75.75 0 011.019.294zm9.194 0a.75.75 0 011.02-.295l1.995 1.101A.75.75 0 0118 5.75v2a.75.75 0 01-1.5 0v-.73l-.884.488a.75.75 0 11-.724-1.314l.806-.444-.806-.444a.75.75 0 01-.295-1.02zM7.343 8.284a.75.75 0 011.02-.294L10 8.893l1.638-.903a.75.75 0 11.724 1.313l-1.612.89v1.557a.75.75 0 01-1.5 0v-1.557l-1.612-.89a.75.75 0 01-.295-1.019zM2.75 11.5a.75.75 0 01.75.75v1.557l1.608.887a.75.75 0 01-.724 1.314l-1.996-1.101A.75.75 0 012 14.25v-2a.75.75 0 01.75-.75zm14.5 0a.75.75 0 01.75.75v2a.75.75 0 01-.388.657l-1.996 1.1a.75.75 0 11-.724-1.313l1.608-.887V12.25a.75.75 0 01.75-.75zm-7.25 4a.75.75 0 01.75.75v.73l.888-.49a.75.75 0 01.724 1.313l-2 1.104a.75.75 0 01-.724 0l-2-1.104a.75.75 0 11.724-1.313l.888.49v-.73a.75.75 0 01.75-.75z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span>MyGardenPal</span>
                                </h1>
                            </header>
                            {/* END Header */}

                            {/* Sign Up Form */}
                            <div
                                className="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
                                <div className="grow p-5 md:px-16 md:py-12">
                                    <p className="text-center">Almost done! Please check your email inbox, including the spam folder, to verify your email address and complete the registration process.</p>
                                </div>
                            </div>
                            {/* END Sign Up Form */}

                        </section>
                        {/* END Sign Up Section */}
                    </div>
                </main>
                {/* END Page Content */}
            </div>
            {/* END Page Container */}

            {/* END Pages: Sign Up: Boxed */}
        </>
    )
}

export default VerifyEmail
import React from 'react';
import { FormData } from '../../types/types';
import { db, auth } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from "react-router-dom";

type ReviewSubmitProps = {
    prevStep: () => void;
    submitData: () => void;
    gardenData: FormData;
};

export default function ReviewAndSubmit({ prevStep, submitData, gardenData }: ReviewSubmitProps) {
    const navigate = useNavigate();

    const user = auth.currentUser;
    console.log(user);

    const handleSubmit = async () => {

        if (!user) {
            console.error('User is not authenticated');
            return;
        }

        try {
            const gardenDoc = {
                ...gardenData,
                userId: user.uid
            };
            await addDoc(collection(db, 'gardens'), gardenDoc);
            console.log('Garden data saved successfully');
            navigate("/admin/pricing");
        } catch (error) {
            console.error('Error saving garden data: ', error);
        }
    };

    return (


        <div className='bg-white mt-8 py-16 rounded-lg flex justify-center'>
            <div className='w-1/2'>
                <h2 className="text-2xl font-bold text-center">Please, review your filled data</h2>

                <div className="mt-12 mb-20 grid grid-cols-1 md:grid-cols-2 gap-8 text-center">
                    <div>
                        <h3 className="text-base font-semibold">Garden Measurement</h3>
                        <p>{gardenData.measurement}</p>
                    </div>
                    <div>
                        <h3 className="text-base font-semibold">Garden Size</h3>
                        <p>{gardenData.size} {gardenData.measurement === 'sq' ? 'Square Feet' : 'Square Meters'}</p>
                    </div>
                    <div>
                        <h3 className="text-base font-semibold">Sunlight Amount</h3>
                        <p>{gardenData.sunlight}</p>
                    </div>
                    <div>
                        <h3 className="text-base font-semibold">Soil Type</h3>
                        <p>{gardenData.soilType}</p>
                    </div>
                    <div>
                        <h3 className="text-base font-semibold">Climate</h3>
                        <p>{gardenData.climate}</p>
                    </div>
                    <div>
                        <h3 className="text-base font-semibold">Plant Types</h3>
                        <p>{gardenData.plantTypes.join(', ')}</p>
                    </div>
                    <div>
                        <h3 className="text-base font-semibold">Color Preferences</h3>
                        <p>{Array.isArray(gardenData.colorPreferences) ? gardenData.colorPreferences.join(', ') : gardenData.colorPreferences}</p>
                    </div>
                    <div>
                        <h3 className="text-base font-semibold">Special Requirements</h3>
                        <p>{gardenData.specialRequirements}</p>
                    </div>
                </div>

                <div className='flex justify-center gap-6 mt-8'>
                    <button
                        onClick={prevStep}
                        className="inline-flex items-center justify-center cursor-pointer gap-2 rounded-lg border border-emerald-200 bg-emerald-100 px-4 py-2 font-semibold leading-6 text-emerald-800 hover:border-emerald-300 hover:text-emerald-900 hover:shadow-sm focus:ring focus:ring-emerald-300/25 active:border-emerald-200 active:shadow-none dark:border-emerald-200 dark:bg-emerald-200 dark:hover:border-emerald-300 dark:hover:bg-emerald-300 dark:focus:ring-emerald-500/50 dark:active:border-emerald-200 dark:active:bg-emerald-200"
                    >
                        Back
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="inline-flex items-center justify-center cursor-pointer gap-2 rounded-lg border border-emerald-700 bg-emerald-700 px-4 py-2 font-semibold leading-6 text-white hover:border-emerald-600 hover:bg-emerald-600 hover:text-white focus:ring focus:ring-emerald-400/50 active:border-emerald-700 active:bg-emerald-700 dark:focus:ring-emerald-400/90"
                    >
                        Confirm & Submit
                    </button>
                </div>
            </div>
        </div>
    );
}

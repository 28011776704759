import React from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {FormData} from "../../types/types";

type PlantPreferencesProps = {
    nextStep: () => void;
    prevStep: () => void;
    gardenData: any;
    setGardenData: (data: any) => void;
};

export default function PlantPreferences({ nextStep, prevStep, gardenData, setGardenData }: PlantPreferencesProps) {
    //console.log(gardenData);
    const { register, handleSubmit } = useForm<FormData>({
        defaultValues: gardenData
    });

    const onSubmit: SubmitHandler<FormData> = (data) => {
        console.log(data);
        setGardenData(data);
        nextStep();
    };

    return (
        <>
            <div className='bg-white mt-8 py-16 rounded-lg flex justify-center'>
                <div className='w-1/2'>
                    <h2 className="text-2xl font-bold text-center">What are your plant preferences for your garden?</h2>
                    {/* Form Elements: Inputs */}
                    <form className="mt-8 space-y-10 dark:text-gray-100" onSubmit={handleSubmit(onSubmit)}>
                        {/* Form Radio Groups: Simple Horizontal Multiple */}
                        <div className="space-y-2 flex flex-col items-center text-center dark:text-gray-100">
                            <div className="font-medium">Plant Types</div>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                What types of plants do you prefer in your garden?
                            </p>
                            <div className="flex flex-wrap items-center gap-4">
                                <label className="group relative flex">
                                    <input
                                        type="checkbox"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="cb_updates"
                                        //name="cb_updates"
                                        value="flowers"
                                        {...register("plantTypes")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Flowers
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="checkbox"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="cb_promotions"
                                        //name="cb_promotions"
                                        value="shrubs"
                                        {...register("plantTypes")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Shrubs
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="checkbox"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="cb_posts"
                                        //name="cb_posts"
                                        value="trees"
                                        {...register("plantTypes")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Trees
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="checkbox"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="cb_subscriptions"
                                        //name="cb_subscriptions"
                                        value="dont-know"
                                        {...register("plantTypes")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              I don't know...
            </span>
                                </label>
                            </div>
                        </div>
                        {/* END Form Radio Groups: Simple Horizontal Multiple */}

                        {/* Select Box */}
                        <div className="space-y-1 flex flex-col items-center text-center">
                            <label htmlFor="colorpreferences" className="font-medium">
                                Color Preferences
                            </label>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                Do you have any color preferences for your plants?
                            </p>
                            <select
                                id="colorpreferences"
                                //name="colorpreferences"
                                className="block w-full text-center rounded-lg border border-gray-200 px-3 py-2 leading-6 focus:border-emerald-500 focus:ring focus:ring-emerald-500/50 dark:border-gray-600 dark:bg-gray-800 dark:focus:border-emerald-500"
                                {...register("colorPreferences")}
                            >
                                <option value="reds">Reds</option>
                                <option value="yellows">Yellows</option>
                                <option value="blues">Blues</option>
                                <option value="whites">Whites</option>
                                <option value="mixed">Mixed</option>
                            </select>
                        </div>
                        {/* END Select Box */}

                        {/* Textarea */}
                        <div className="space-y-1 flex flex-col items-center text-center">
                            <label htmlFor="details" className="font-medium">
                                Special Requirements
                            </label>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                Please make sure to choose a strong one
                            </p>
                            <textarea
                                id="details"
                                //name="details"
                                rows={4}
                                placeholder="Any special requirements for the plants you want?"
                                className="block w-full text-center rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-emerald-500 focus:ring focus:ring-emerald-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-emerald-500"
                                //value=""
                                {...register("specialRequirements")}
                            />
                        </div>
                        {/* END Textarea */}

                        <div className='flex justify-center gap-6'>
                            {/* Button (normal) */}
                            <input
                                onClick={prevStep}
                                type="button"
                                className="inline-flex items-center justify-center cursor-pointer gap-2 rounded-lg border border-emerald-200 bg-emerald-100 px-4 py-2 font-semibold leading-6 text-emerald-800 hover:border-emerald-300 hover:text-emerald-900 hover:shadow-sm focus:ring focus:ring-emerald-300/25 active:border-emerald-200 active:shadow-none dark:border-emerald-200 dark:bg-emerald-200 dark:hover:border-emerald-300 dark:hover:bg-emerald-300 dark:focus:ring-emerald-500/50 dark:active:border-emerald-200 dark:active:bg-emerald-200"
                                value="Back"
                            />
                            {/* END Button (normal) */}

                            {/* Button (normal) */}
                            <input
                                type="submit"
                                className="inline-flex items-center justify-center cursor-pointer gap-2 rounded-lg border border-emerald-700 bg-emerald-700 px-4 py-2 font-semibold leading-6 text-white hover:border-emerald-600 hover:bg-emerald-600 hover:text-white focus:ring focus:ring-emerald-400/50 active:border-emerald-700 active:bg-emerald-700 dark:focus:ring-emerald-400/90"
                                value="Continue to Review"
                            />
                            {/* END Button (normal) */}
                        </div>

                        {/* END Text Input */}
                    </form>
                    {/* END Form Elements: Inputs */}
                </div>
            </div>
        </>
    );
}

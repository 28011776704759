import React from "react";
import Hero from "./Hero";
import AboutCompanyWithStats from "./AboutCompanyWithStats";
import TeamCirclePhotoswithTitle from "./TeamCirclePhotoswithTitle";
import TestimonialsBoxedMultipleAlternate from "./TestimonialsBoxedMultipleAlternate";
import FAQToTheSide from "./FAQToTheSide";
import FeaturesModernAlternatewithIcons from "./FeaturesKeyFeaturesList";
import CTASimple from "./CtaSimple";
import Footer from "./Footer";

const Home: React.FC = () => {
    return (
        <div>
            <Hero />
            <AboutCompanyWithStats />
            <TeamCirclePhotoswithTitle />
            <FeaturesModernAlternatewithIcons />
            <TestimonialsBoxedMultipleAlternate />
            <FAQToTheSide />
            <CTASimple />
            <Footer />
        </div>
    );
};

export default Home;

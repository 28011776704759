import React, {useState} from 'react';
import { Link, useNavigate } from "react-router-dom";

export default function Welcome() {
    return (
        <>
            {/* CTA Section: Simple With Logo */}
            <div className="bg-white dark:bg-gray-800 dark:text-gray-100">
                <div className="container mx-auto space-y-8 px-4 py-16 lg:px-8 lg:py-32 xl:max-w-7xl">
                    {/* Heading */}
                    <div className="text-center">
                        <div className="relative mx-auto mb-10 inline-flex size-20 items-center justify-center text-emerald-600 dark:text-emerald-300">
                            <div className="absolute inset-0 rotate-6 scale-105 rounded-xl bg-emerald-200 dark:bg-emerald-900" />
                            <div className="absolute inset-0 -rotate-6 scale-105 rounded-xl bg-emerald-100 dark:bg-emerald-800" />
                            <div className="relative">
                                <svg
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="hi-solid hi-fire inline-block size-10"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                        <h2 className="mb-4 text-4xl font-black text-black dark:text-white">
                            Your journey to a beautiful garden begins
                            <span className="text-emerald-600 dark:text-emerald-500"> now</span>!
                        </h2>
                        <h3 className="mx-auto text-xl font-medium leading-relaxed text-gray-700 lg:w-2/3 dark:text-gray-300">
                            Tell us about your garden, and let our expert team do the rest. Your custom garden plant plan will be ready for you to see soon.
                        </h3>
                    </div>
                    {/* END Heading */}

                    {/* Action */}
                    <div className="text-center">
                        <Link
                            to="/admin/interactive-guide"
                            className="inline-flex items-center justify-center gap-2 rounded-lg border border-emerald-700 bg-emerald-700 px-6 py-3 font-semibold leading-6 text-white hover:border-emerald-600 hover:bg-emerald-600 hover:text-white focus:ring focus:ring-emerald-400/50 active:border-emerald-700 active:bg-emerald-700 dark:focus:ring-emerald-400/90"
                        >
                            <span>Let’s get started</span>
                            <svg
                                className="hi-mini hi-arrow-right inline-block size-5 opacity-50"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </Link>
                    </div>
                    {/* END Action */}
                </div>
            </div>
            {/* END CTA Section: Simple With Logo */}
        </>
    );
}

import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from './context/AuthContext';

interface ProtectedRouteProps {
    children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { user, loading } = UserAuth();

    if (loading) {
        return <div>Loading...</div>; // Můžete zde přidat nějaký spinner nebo loading indikátor
    }

    console.log('user', user);
    if (!user) {
        return <Navigate to='/' />;
    }
    return <>{children}</>;
};

export default ProtectedRoute;

export default function FAQToTheSide() {
    return (
        <>
            {/* FAQ Section: To The Side */}
            <div className="bg-green-50 dark:bg-gray-900 dark:text-gray-100">
                <div className="container mx-auto space-y-16 px-4 py-16 lg:flex lg:justify-between lg:gap-8 lg:space-y-0 lg:px-8 lg:py-32 xl:max-w-7xl">
                    {/* Heading */}
                    <div className="text-center lg:text-left">
                        <div className="mb-1 text-sm font-bold uppercase tracking-wider text-emerald-600 dark:text-emerald-500">
                            We Answer
                        </div>
                        <h2 className="mb-4 text-4xl font-black text-black dark:text-white">
                            FAQ
                        </h2>
                        <h3 className="text-xl font-medium leading-relaxed text-gray-700 dark:text-gray-300">
                            Be sure to{" "}
                            <a
                                href="#"
                                className="text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300"
                            >
                                get in touch
                            </a>{" "}
                            and let us know if you have any further questions.
                        </h3>
                    </div>
                    {/* END Heading */}

                    {/* FAQ */}
                    <div className="space-y-8 lg:w-3/5 lg:flex-none">
                        <div>
                            <h4 className="mb-2 font-semibold">
                                How does the garden design process work?
                            </h4>
                            <p className="leading-relaxed text-gray-600 dark:text-gray-400">
                                Our process is simple. Provide us with the dimensions of your garden or the specific section you want to enhance. Select the elements you'd like to include, such as pools, benches, raised beds, or fire pits, and our team of garden architects will create a comprehensive design plan for you.
                            </p>
                        </div>
                        <div>
                            <h4 className="mb-2 font-semibold">
                                Can I design just a part of my garden?
                            </h4>
                            <p className="leading-relaxed text-gray-600 dark:text-gray-400">
                                Absolutely! Whether you want to design your entire garden or just a specific section, our tool allows you to customize your project according to your needs and preferences.
                            </p>
                        </div>
                        <div>
                            <h4 className="mb-2 font-semibold">
                                What kind of support do you offer?
                            </h4>
                            <p className="leading-relaxed text-gray-600 dark:text-gray-400">
                                We offer comprehensive support through email and our community forums. Our team of garden experts is always ready to help you with any questions or issues you might have.
                            </p>
                        </div>
                        <div>
                            <h4 className="mb-2 font-semibold">
                                Are the design plans customizable?
                            </h4>
                            <p className="leading-relaxed text-gray-600 dark:text-gray-400">
                                Yes, our design plans are fully customizable. You can choose from a wide range of elements and styles to create a garden that perfectly matches your vision.
                            </p>
                        </div>
                        <div>
                            <h4 className="mb-2 font-semibold">
                                How do I plant the recommended plants?
                            </h4>
                            <p className="leading-relaxed text-gray-600 dark:text-gray-400">
                                We provide detailed planting guides for each plant in your design. These guides include information on the best planting times, soil preparation, spacing, and care tips to ensure your plants thrive.
                            </p>
                        </div>
                        <div>
                            <h4 className="mb-2 font-semibold">
                                How do I take care of my plants?
                            </h4>
                            <p className="leading-relaxed text-gray-600 dark:text-gray-400">
                                Each plant in your design comes with a comprehensive care guide. This includes watering schedules, fertilization recommendations, pruning tips, and pest control measures to keep your garden healthy and beautiful year-round.
                            </p>
                        </div>
                        <div>
                            <h4 className="mb-2 font-semibold">
                                Where can I buy the plants recommended in my design?
                            </h4>
                            <p className="leading-relaxed text-gray-600 dark:text-gray-400">
                                We have partnerships with several reputable nurseries and online plant stores. You can find a list of recommended suppliers in your design plan, along with links to purchase the plants directly.
                            </p>
                        </div>
                        <div>
                            <h4 className="mb-2 font-semibold">
                                Can you recommend companies to help with the garden installation?
                            </h4>
                            <p className="leading-relaxed text-gray-600 dark:text-gray-400">
                                Yes, we can recommend trusted landscaping companies and contractors in your area to help with the installation of your garden design. We only recommend companies with a proven track record of quality and reliability.
                            </p>
                        </div>
                    </div>
                    {/* END FAQ */}
                </div>
            </div>
            {/* END FAQ Section: To The Side */}
        </>
    );
}

import React from "react";

export default function TestimonialsBoxedMultipleAlternate() {
    return (
        <>
            {/* Testimonials Section: Boxed Multiple Alternate */}
            <div className="bg-white dark:bg-gray-900 dark:text-gray-100">
                <div className="container mx-auto space-y-16 px-4 py-16 lg:px-8 lg:py-32 xl:max-w-7xl">
                    {/* Heading */}
                    <div className="text-center">
                        <div className="mx-auto mb-2 inline-block rounded bg-emerald-100 px-1.5 py-0.5 text-sm font-semibold text-emerald-700 dark:bg-emerald-900 dark:text-emerald-200">
                            Get started today
                        </div>
                        <h2 className="mb-4 text-4xl font-black text-black dark:text-white">
                            Real Feedback from Our Clients
                        </h2>
                        <h3 className="mx-auto mb-5 text-xl font-medium leading-relaxed text-gray-700 lg:w-2/3 dark:text-gray-300">
                            Our clients are thrilled with their new gardens. Here's what they have to say about working with us.
                        </h3>
                    </div>
                    {/* END Heading */}

                    {/* Feedback */}
                    <div className="grid grid-cols-1 gap-4 md:gap-8 lg:grid-cols-3">
                        <div className="group relative flex flex-col items-stretch rounded-xl border border-gray-200 px-6 pb-6 pt-8 md:px-8 md:pb-8 dark:border-gray-700/75">
                            <div className="absolute right-0 top-0 mr-4 mt-3 font-serif text-7xl text-gray-200 opacity-75 dark:text-gray-700/75">
                                “
                            </div>
                            <blockquote className="relative flex grow flex-col">
                                <div className="mb-6">
                                    <div className="inline-flex origin-top-left items-center rounded-lg border border-emerald-100 bg-emerald-50 px-2 py-1 text-emerald-600 transition ease-out group-hover:scale-110 dark:border-emerald-800 dark:bg-emerald-900 dark:text-emerald-400">
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <p className="mb-5 grow text-sm font-medium leading-relaxed text-gray-700 dark:text-gray-400">
                                    "The team at MyGardenPal transformed our backyard into a stunning garden oasis. Their expertise and attention to detail are unmatched."
                                </p>
                                <footer className="flex flex-none items-center gap-4">
                                    <a
                                        href="#"
                                        className="dark:shadow-dark-gray-950 size-12 flex-none overflow-hidden rounded-full border-2 border-gray-50 transition duration-150 ease-out hover:scale-125 hover:border-white hover:shadow-md active:scale-110 active:border-gray-50 active:shadow-sm dark:border-gray-900"
                                    >
                                        <img
                                            src="https://cdn.tailkit.com/media/placeholders/avatar-sibVwORYqs0-160x160.jpg"
                                            alt="Avatar Photo"
                                        />
                                    </a>
                                    <div>
                                        <a
                                            href="#"
                                            className="font-semibold text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300"
                                        >
                                            Alex Sader
                                        </a>
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                            Homeowner
                                        </p>
                                    </div>
                                </footer>
                            </blockquote>
                        </div>
                        <div className="group relative flex flex-col items-stretch rounded-xl border border-gray-200 px-6 pb-6 pt-8 md:px-8 md:pb-8 dark:border-gray-700/75">
                            <div className="absolute right-0 top-0 mr-4 mt-3 font-serif text-7xl text-gray-200 opacity-75 dark:text-gray-700/75">
                                “
                            </div>
                            <blockquote className="relative flex grow flex-col">
                                <div className="mb-6">
                                    <div className="inline-flex origin-top-left items-center rounded-lg border border-emerald-100 bg-emerald-50 px-2 py-1 text-emerald-600 transition ease-out group-hover:scale-110 dark:border-emerald-800 dark:bg-emerald-900 dark:text-emerald-400">
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <p className="mb-5 grow text-sm font-medium leading-relaxed text-gray-700 dark:text-gray-400">
                                    "MyGardenPal's team made it so easy to get the garden I've always wanted. Their professional service and expertise are truly impressive."
                                </p>
                                <footer className="flex flex-none items-center gap-4">
                                    <a
                                        href="#"
                                        className="dark:shadow-dark-gray-950 size-12 flex-none overflow-hidden rounded-full border-2 border-gray-50 transition duration-150 ease-out hover:scale-125 hover:border-white hover:shadow-md active:scale-110 active:border-gray-50 active:shadow-sm dark:border-gray-900"
                                    >
                                        <img
                                            src="https://cdn.tailkit.com/media/placeholders/avatar-DLKR_x3T_7s-160x160.jpg"
                                            alt="Avatar Photo"
                                        />
                                    </a>
                                    <div>
                                        <a
                                            href="#"
                                            className="font-semibold text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300"
                                        >
                                            Sue Doe
                                        </a>
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                            Garden Enthusiast
                                        </p>
                                    </div>
                                </footer>
                            </blockquote>
                        </div>
                        <div className="group relative flex flex-col items-stretch rounded-xl border border-gray-200 px-6 pb-6 pt-8 md:px-8 md:pb-8 dark:border-gray-700/75">
                            <div className="absolute right-0 top-0 mr-4 mt-3 font-serif text-7xl text-gray-200 opacity-75 dark:text-gray-700/75">
                                “
                            </div>
                            <blockquote className="relative flex grow flex-col">
                                <div className="mb-6">
                                    <div className="inline-flex origin-top-left items-center rounded-lg border border-emerald-100 bg-emerald-50 px-2 py-1 text-emerald-600 transition ease-out group-hover:scale-110 dark:border-emerald-800 dark:bg-emerald-900 dark:text-emerald-400">
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <svg
                                            className="hi-mini hi-star inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </div>
                                <p className="mb-5 grow text-sm font-medium leading-relaxed text-gray-700 dark:text-gray-400">
                                    "I couldn't be happier with my new garden, thanks to MyGardenPal. They made the entire process simple and enjoyable."
                                </p>
                                <footer className="flex flex-none items-center gap-4">
                                    <a
                                        href="#"
                                        className="dark:shadow-dark-gray-950 size-12 flex-none overflow-hidden rounded-full border-2 border-gray-50 transition duration-150 ease-out hover:scale-125 hover:border-white hover:shadow-md active:scale-110 active:border-gray-50 active:shadow-sm dark:border-gray-900"
                                    >
                                        <img
                                            src="https://cdn.tailkit.com/media/placeholders/avatar-euZ2n8dGUcQ-160x160.jpg"
                                            alt="Avatar Photo"
                                        />
                                    </a>
                                    <div>
                                        <a
                                            href="#"
                                            className="font-semibold text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300"
                                        >
                                            Mike Lee
                                        </a>
                                        <p className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                            Homeowner
                                        </p>
                                    </div>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                    {/* END Feedback */}
                </div>
            </div>
            {/* END Testimonials Section: Boxed Multiple Alternate */}
        </>
    );
}

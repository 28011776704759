import { useState } from "react";
import { auth, db } from "../firebase";
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import backgroundImage from '../assets/login-min.jpg';

const Signin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);

    const loginWithUsernameAndPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsProcessing(true);

        try {
            await setPersistence(auth, browserLocalPersistence);
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Zkontrolujte, zda uživatelský dokument existuje
            const userDocRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                // Pokud neexistuje, vytvořte nový dokument s výchozími daty
                await setDoc(userDocRef, {
                    email: user.email,
                    isPayingUser: false // výchozí hodnota, kterou můžete později aktualizovat
                });
            }

            navigate("/admin");
        } catch {
            toast.error("You entered a wrong username or password.");
            setIsProcessing(false);
        }
    }


    return (
        <>
            <Toaster />
            {/* Pages: Sign In: Boxed Alternate */}

            {/* Page Container */}
            <div
                id="page-container"
                className="mx-auto flex min-h-dvh w-full min-w-[320px] flex-col bg-gray-100 dark:bg-gray-900 dark:text-gray-100"
            >
                {/* Page Content */}
                <main id="page-content" className="flex max-w-full flex-auto flex-col">
                    <div className="mx-auto flex min-h-dvh w-full max-w-10xl items-center justify-center bg-gray-200/50 p-4 lg:p-8 dark:bg-transparent">
                        {/* Sign In Section */}
                        <div className="w-full max-w-5xl lg:py-16">
                            {/* Sign In Box */}
                            <div className="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm md:flex-row dark:bg-gray-800 dark:text-gray-100">
                                {/* Sign In Form */}
                                <section className="px-6 py-10 md:w-1/2 md:px-10 lg:p-16">
                                    <header className="mb-8 text-center sm:mt-0 md:text-left">
                                        <h1 className="mb-1 text-2xl font-bold">MyGardenPal</h1>
                                        <h2 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                            Welcome, please sign in
                                        </h2>
                                    </header>
                                    <form className="space-y-6" onSubmit={loginWithUsernameAndPassword}>
                                        <div className="space-y-1">
                                            <label htmlFor="email" className="text-sm font-medium">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                className="block w-full rounded-lg border border-gray-200 px-5 py-3 leading-6 placeholder-gray-500 focus:border-emerald-500 focus:ring focus:ring-emerald-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-emerald-500"
                                                onChange = { (e) => setEmail(e.target.value) }
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <label htmlFor="password" className="text-sm font-medium">
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                placeholder="Enter your password"
                                                className="block w-full rounded-lg border border-gray-200 px-5 py-3 leading-6 placeholder-gray-500 focus:border-emerald-500 focus:ring focus:ring-emerald-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-emerald-500"
                                                onChange = { (e) => setPassword(e.target.value) }
                                            />
                                        </div>
                                        <div>
                                            <div className="mb-5 flex items-center justify-between gap-2">
                                                <label className="flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        id="remember_me"
                                                        name="remember_me"
                                                        className="size-4 rounded border border-gray-200 text-emerald-500 focus:border-emerald-500 focus:ring focus:ring-emerald-500/50 dark:border-gray-600 dark:bg-gray-800 dark:ring-offset-gray-900 dark:checked:border-transparent dark:checked:bg-emerald-500 dark:focus:border-emerald-500"
                                                    />
                                                    <span className="ml-2 text-sm">Remember me</span>
                                                </label>
                                                <Link
                                                    to="/password-reset"
                                                    className="inline-block text-sm font-medium text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300"
                                                >
                                                    Forgot Password?
                                                </Link>
                                            </div>
                                            <button
                                                type="submit"
                                                className="inline-flex w-full items-center justify-center gap-2 rounded-lg border border-emerald-700 bg-emerald-700 px-6 py-3 font-semibold leading-6 text-white hover:border-emerald-600 hover:bg-emerald-600 hover:text-white focus:ring focus:ring-emerald-400/50 active:border-emerald-700 active:bg-emerald-700 dark:focus:ring-emerald-400/90"
                                                disabled={isProcessing}
                                            >
                                                {isProcessing ?
                                                    <>
                                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                             xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10"
                                                                    stroke="currentColor" stroke-width="4"></circle>
                                                            <path className="opacity-75" fill="currentColor"
                                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        <span>Validating...</span>
                                                    </>
                                                    : (
                                                        <>
                                                            <svg
                                                                className="hi-mini hi-arrow-uturn-right inline-block size-5 opacity-50"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                                aria-hidden="true"
                                                            >
                                                            <path
                                                                fillRule="evenodd"
                                                                d="M12.207 2.232a.75.75 0 00.025 1.06l4.146 3.958H6.375a5.375 5.375 0 000 10.75H9.25a.75.75 0 000-1.5H6.375a3.875 3.875 0 010-7.75h10.003l-4.146 3.957a.75.75 0 001.036 1.085l5.5-5.25a.75.75 0 000-1.085l-5.5-5.25a.75.75 0 00-1.06.025z"
                                                                clipRule="evenodd"
                                                            />
                                                        </svg>
                                                        <span>Sign In</span>
                                                    </>
                                                    )}
                                            </button>
                                        </div>

                                    </form>
                                    <div className="mt-10 text-sm text-gray-500 dark:text-gray-400">
                                        Don’t have an account yet? <Link to="/signup"
                                                                         className="font-medium text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300">Sign
                                        up</Link>
                                    </div>
                                </section>
                                {/* END Sign In Form */}

                                {/* END Sign In Extra */}
                                <div
                                    className="relative flex items-center bg-cover px-6 py-10 md:w-1/2 md:px-10 lg:p-16"
                                    style={{
                                        backgroundImage: `url(${backgroundImage})`,
                                    }}
                                >
                                    <div className="absolute inset-0 bg-emerald-900/70" />
                                    <div className="relative text-center sm:text-left">
                                        <p className="mb-2 font-semibold leading-relaxed text-white">
                                            When I started with MyGardenPal, I knew nothing about gardening. Now, my backyard is a blooming haven. This tool was a game-changer!
                                        </p>
                                        <p className="text-sm font-medium text-white/75">
                                            Michael Richards
                                        </p>
                                    </div>
                                </div>
                                {/* END Sign In Extra */}
                            </div>
                            {/* END Sign In Box */}

                            {/* Footer */}
                            <div className="mt-5 text-center text-sm text-gray-600 dark:text-gray-400">
                                Powered by&nbsp;
                                <Link
                                    to="/"
                                    className="font-medium text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300"
                                >
                                    MyGardenPal
                                </Link>
                            </div>
                            {/* END Footer */}
                        </div>
                        {/* END Sign In Section */}
                    </div>
                </main>
                {/* END Page Content */}
            </div>
            {/* END Page Container */}

            {/* END Pages: Sign In: Boxed Alternate */}
        </>
    )
}

export default Signin
export default function FeaturesKeyFeaturesList() {
    return (
        <>
            {/* Features Section: Key Features List */}
            <div className="bg-green-50 dark:bg-gray-900 dark:text-gray-100">
                <div className="container mx-auto space-y-16 px-4 py-16 lg:px-8 lg:py-32 xl:max-w-7xl">
                    {/* Heading */}
                    <div className="text-center">
                        <div className="mb-1 text-sm font-bold uppercase tracking-wider text-emerald-600 dark:text-emerald-500">
                            Great Value for Money
                        </div>
                        <h2 className="mb-4 text-4xl font-black text-black dark:text-white">
                            Tailkit is so flexible
                        </h2>
                        <h3 className="mx-auto text-xl font-medium leading-relaxed text-gray-700 lg:w-2/3 dark:text-gray-300">
                            You have the freedom to use it to design websites and web apps for
                            you and your clients, SaaS or even Open Source projects.
                        </h3>
                    </div>
                    {/* END Heading */}

                    {/* Features */}
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-8 lg:grid-cols-3">
                        <div className="flex items-center gap-3">
                            <div className="flex flex-none items-center justify-center text-emerald-500 dark:text-emerald-400">
                                <svg
                                    className="hi-mini hi-check-circle inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <h4 className="font-semibold">One-time purchase</h4>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-none items-center justify-center text-emerald-500 dark:text-emerald-400">
                                <svg
                                    className="hi-mini hi-check-circle inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <h4 className="font-semibold">You can charge your end users</h4>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-none items-center justify-center text-emerald-500 dark:text-emerald-400">
                                <svg
                                    className="hi-mini hi-check-circle inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <h4 className="font-semibold">You can use it in your courses</h4>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-none items-center justify-center text-emerald-500 dark:text-emerald-400">
                                <svg
                                    className="hi-mini hi-check-circle inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <h4 className="font-semibold">Great for Commercial projects</h4>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-none items-center justify-center text-emerald-500 dark:text-emerald-400">
                                <svg
                                    className="hi-mini hi-check-circle inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <h4 className="font-semibold">Great for Open Source projects</h4>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-none items-center justify-center text-emerald-500 dark:text-emerald-400">
                                <svg
                                    className="hi-mini hi-check-circle inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <h4 className="font-semibold">Great for Personal projects</h4>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-none items-center justify-center text-emerald-500 dark:text-emerald-400">
                                <svg
                                    className="hi-mini hi-check-circle inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <h4 className="font-semibold">Great for learning as well</h4>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-none items-center justify-center text-emerald-500 dark:text-emerald-400">
                                <svg
                                    className="hi-mini hi-check-circle inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <h4 className="font-semibold">Free amazing updates</h4>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex flex-none items-center justify-center text-emerald-500 dark:text-emerald-400">
                                <svg
                                    className="hi-mini hi-check-circle inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <h4 className="font-semibold">Friendly email support</h4>
                        </div>
                    </div>
                    {/* END Features */}
                </div>
            </div>
            {/* END Features Section: Key Features List */}
        </>
    );
}

import React from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {FormData} from "../../types/types";

type BasicGardenInformationsProps = {
    prevStep: () => void;
    nextStep: () => void;
    gardenData: FormData;
    setGardenData: (data: FormData) => void;
};

export default function BasicGardenInformations({ prevStep, nextStep, gardenData, setGardenData }: BasicGardenInformationsProps) {
    const { register, handleSubmit } = useForm<FormData>({
        defaultValues: gardenData
    });

    const onSubmit: SubmitHandler<FormData> = (data) => {
        setGardenData(data);
        nextStep();
    };

    return (
        <>
            <div className='bg-white mt-8 py-16 rounded-lg flex justify-center'>
                <div className='w-1/2'>
                    {/* Form Elements: Inputs */}
                    <form className="space-y-12 dark:text-gray-100" onSubmit={handleSubmit(onSubmit)}>

                        {/* Form Radio Groups: Simple Horizontal */}
                        <div className="space-y-2 dark:text-gray-100">
                            <div className="font-medium text-center">Sunlight Amount</div>
                            <p className="text-sm text-center text-gray-500 dark:text-gray-400">
                                How much sunlight does your garden receive?
                            </p>
                            <div className="flex flex-wrap justify-center items-center gap-4 pt-2">
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group1_a"
                                        //name="radio_group1"
                                        value="full-sun"
                                        {...register("sunlight")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Full Sun (6+ hours/day)
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group1_b"
                                        //name="radio_group1"
                                        value="partial-sun-shade"
                                        {...register("sunlight")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Partial Sun/Shade (3-6 hours/day)
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group1_c"
                                        //name="radio_group1"
                                        value="full-shade"
                                        {...register("sunlight")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Full Shade (less than 3 hours/day)
            </span>
                                </label>
                            </div>
                        </div>
                        {/* END Form Radio Groups: Simple Horizontal */}

                        {/* Form Radio Groups: Simple Horizontal */}
                        <div className="space-y-2 dark:text-gray-100">
                            <div className="font-medium text-center">Soil Type</div>
                            <p className="text-sm text-center text-gray-500 dark:text-gray-400">
                                What type of soil does your garden have?
                            </p>
                            <div className="flex flex-wrap justify-center items-center gap-4 pt-2">
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group2_a"
                                        //name="radio_group2"
                                        value="sandy"
                                        {...register("soilType")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Sandy
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group2_b"
                                        //name="radio_group2"
                                        value="loamy"
                                        {...register("soilType")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Loamy
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group2_c"
                                        //name="radio_group2"
                                        value="clay"
                                        {...register("soilType")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Clay
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group2_d"
                                        //name="radio_group2"
                                        value="not-sure"
                                        {...register("soilType")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              I'm not sure
            </span>
                                </label>
                            </div>
                        </div>
                        {/* END Form Radio Groups: Simple Horizontal */}

                        {/* Form Radio Groups: Simple Horizontal */}
                        <div className="space-y-2 dark:text-gray-100">
                            <div className="font-medium text-center">Climate</div>
                            <p className="text-sm text-center text-gray-500 dark:text-gray-400">
                                Describe the climate in your area:
                            </p>
                            <div className="flex flex-wrap justify-center items-center gap-4 pt-2">
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group3_a"
                                        //name="radio_group2"
                                        value="dry"
                                        {...register("climate")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Dry
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group3_b"
                                        //name="radio_group2"
                                        value="humid"
                                        {...register("climate")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Humid
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group3_c"
                                        //name="radio_group2"
                                        value="temperate"
                                        {...register("climate")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Temperate
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group3_d"
                                        //name="radio_group2"
                                        value="not-sure"
                                        {...register("climate")}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              I'm not sure
            </span>
                                </label>
                            </div>
                        </div>
                        {/* END Form Radio Groups: Simple Horizontal */}

                        <div className='flex justify-center gap-6'>
                            {/* Button (normal) */}
                            <input
                                onClick={prevStep}
                                type="button"
                                className="inline-flex items-center justify-center cursor-pointer gap-2 rounded-lg border border-emerald-200 bg-emerald-100 px-4 py-2 font-semibold leading-6 text-emerald-800 hover:border-emerald-300 hover:text-emerald-900 hover:shadow-sm focus:ring focus:ring-emerald-300/25 active:border-emerald-200 active:shadow-none dark:border-emerald-200 dark:bg-emerald-200 dark:hover:border-emerald-300 dark:hover:bg-emerald-300 dark:focus:ring-emerald-500/50 dark:active:border-emerald-200 dark:active:bg-emerald-200"
                                value="Back"
                            />
                            {/* END Button (normal) */}

                            {/* Button (normal) */}
                            <input
                                type="submit"
                                className="inline-flex cursor-pointer items-center justify-center gap-2 rounded-lg border border-emerald-700 bg-emerald-700 px-4 py-2 font-semibold leading-6 text-white hover:border-emerald-600 hover:bg-emerald-600 hover:text-white focus:ring focus:ring-emerald-400/50 active:border-emerald-700 active:bg-emerald-700 dark:focus:ring-emerald-400/90"
                                value="Continue to Plant Preferences"
                            />
                            {/* END Button (normal) */}
                        </div>

                        {/* END Text Input */}
                    </form>
                    {/* END Form Elements: Inputs */}
                </div>
            </div>
        </>
    );
}

import React, { useState, useEffect } from "react";
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { UserProfile } from '../../types/userProfile';

// Headless UI 2.x for React, for more info and examples you can check out https://github.com/tailwindlabs/headlessui
import {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Transition,
} from "@headlessui/react";

import InteractiveGuide from "./InteractiveGuide";
import {signOut} from "firebase/auth";
import {auth} from "../../firebase";
import {Link, Outlet, useNavigate} from "react-router-dom";
import Welcome from "./Welcome";
import PricingToTheSide from "./PricingToTheSide";

export default function AdminLayout() {
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);

    const navigate = useNavigate();
    const user = auth.currentUser;

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (user) {
                try {
                    const userDocRef = doc(db, 'users', user.uid);
                    const userDoc = await getDoc(userDocRef);
                    if (userDoc.exists()) {
                        setUserProfile(userDoc.data() as UserProfile);
                    } else {
                        console.error('No such document!');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                console.error('User not authenticated');
                navigate('/login'); // Redirect to login if user is not authenticated
            }
        };

        fetchUserProfile();
    }, [user, navigate]);

    const logoutUser = async () => {
        await signOut(auth);
        navigate("/");
    }


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userProfile) {
        return <div>No user data found.</div>;
    }

    return (
        <>
            {/* Page Container */}
            <div
                id="page-container"
                className="mx-auto flex min-h-dvh w-full min-w-[320px] flex-col bg-gray-100 dark:bg-gray-900 dark:text-gray-100"
            >
                {/* Page Header */}
                <header
                    id="page-header"
                    className="z-1 flex flex-none items-center bg-white shadow-sm dark:bg-gray-800"
                >
                    <div className="container mx-auto px-4 lg:px-8 xl:max-w-7xl">
                        <div className="flex justify-between py-4">
                            {/* Left Section */}
                            <div className="flex items-center">
                                {/* Logo */}
                                <Link
                                    to="/admin"
                                    className="group inline-flex items-center gap-2 text-lg font-bold tracking-wide text-gray-900 hover:text-gray-600 dark:text-gray-100 dark:hover:text-gray-300"
                                >
                                    <span>MyGardenPal</span>
                                </Link>
                                {/* END Logo */}
                            </div>
                            {/* END Left Section */}

                            {/* Right Section */}
                            <div className="flex items-center gap-2 lg:gap-5">
                                {/* Desktop Navigation */}
                                <nav className="hidden items-center gap-2 lg:flex">
                                    <Link
                                        to="/admin"
                                        className="group flex items-center gap-2 rounded-lg border border-emerald-100 bg-emerald-50 px-3 py-2 text-sm font-medium text-emerald-600 dark:border-transparent dark:bg-gray-700 dark:text-white"
                                    >
                                        <svg
                                            className="hi-mini hi-home inline-block size-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <span>Dashboard</span>
                                    </Link>
                                    <a
                                        href="#"
                                        className="group flex items-center gap-2 rounded-lg border border-transparent px-3 py-2 text-sm font-medium text-gray-800 hover:bg-emerald-50 hover:text-emerald-600 active:border-emerald-100 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white dark:active:border-gray-600"
                                    >
                                        <svg
                                            className="hi-mini hi-users inline-block size-5 opacity-25 group-hover:opacity-100"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
                                        </svg>
                                        <span>24/7 Support</span>
                                    </a>
                                </nav>
                                {/* END Desktop Navigation */}

                                {/* User Dropdown */}
                                <Menu as="div" className="relative inline-block">
                                    {/* Dropdown Toggle Button */}
                                    <MenuButton className="inline-flex items-center justify-center gap-2 rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-semibold leading-5 text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300/25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700">
                                        <span>{user?.displayName}</span>
                                        <svg
                                            className="hi-mini hi-chevron-down inline-block size-5 opacity-40"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </MenuButton>
                                    {/* END Dropdown Toggle Button */}

                                    {/* Dropdown */}
                                    <Transition
                                        enter="transition ease-out duration-100"
                                        enterFrom="opacity-0 scale-90"
                                        enterTo="opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-90"
                                    >
                                        <MenuItems
                                            modal={false}
                                            className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-lg shadow-xl focus:outline-none dark:shadow-gray-900"
                                        >
                                            <div className="divide-y divide-gray-100 rounded-lg bg-white ring-1 ring-black/5 dark:divide-gray-700 dark:bg-gray-800 dark:ring-gray-700">

                                                <div className="space-y-1 p-2.5">
                                                    <MenuItem>
                                                        {({ focus }) => (
                                                            <Link
                                                                to="/admin/profile"
                                                                className={`group flex items-center justify-between gap-2 rounded-lg border border-transparent px-2.5 py-2 text-sm font-medium ${
                                                                    focus
                                                                        ? "bg-emerald-50 text-emerald-800 dark:border-transparent dark:bg-gray-700/75 dark:text-white"
                                                                        : "text-gray-700 hover:bg-emerald-50 hover:text-emerald-800 active:border-emerald-100 dark:text-gray-200 dark:hover:bg-gray-700/75 dark:hover:text-white dark:active:border-gray-600"
                                                                }`}
                                                            >
                                                                <svg
                                                                    className="hi-mini hi-user-circle inline-block size-5 flex-none opacity-25 group-hover:opacity-50"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-5.5-2.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM10 12a5.99 5.99 0 00-4.793 2.39A6.483 6.483 0 0010 16.5a6.483 6.483 0 004.793-2.11A5.99 5.99 0 0010 12z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                                <span className="grow">Account</span>
                                                            </Link>
                                                        )}
                                                    </MenuItem>
                                                </div>
                                                <div className="space-y-1 p-2.5">
                                                    <MenuItem>
                                                        {({ focus }) => (
                                                            <Link
                                                                to="/"
                                                                onClick = {(e) => logoutUser()}
                                                                className={`group flex items-center justify-between gap-2 rounded-lg border border-transparent px-2.5 py-2 text-sm font-medium ${
                                                                    focus
                                                                        ? "bg-emerald-50 text-emerald-800 dark:border-transparent dark:bg-gray-700/75 dark:text-white"
                                                                        : "text-gray-700 hover:bg-emerald-50 hover:text-emerald-800 active:border-emerald-100 dark:text-gray-200 dark:hover:bg-gray-700/75 dark:hover:text-white dark:active:border-gray-600"
                                                                }`}
                                                            >
                                                                <svg
                                                                    className="hi-mini hi-lock-closed inline-block size-5 flex-none opacity-25 group-hover:opacity-50"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                                <span className="grow">Sign out</span>
                                                            </Link>
                                                        )}
                                                    </MenuItem>
                                                </div>
                                            </div>
                                        </MenuItems>
                                    </Transition>
                                    {/* END Dropdown */}
                                </Menu>
                                {/* END User Dropdown */}

                                {/* Toggle Mobile Navigation */}
                                <div className="lg:hidden">
                                    <button
                                        onClick={() => setMobileNavOpen(!mobileNavOpen)}
                                        type="button"
                                        className="inline-flex items-center justify-center gap-2 rounded-lg border border-gray-200 bg-white px-3 py-2 text-sm font-semibold leading-5 text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-sm focus:ring focus:ring-gray-300/25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600/40 dark:active:border-gray-700"
                                    >
                                        <svg
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="hi-solid hi-menu inline-block size-5"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                {/* END Toggle Mobile Navigation */}
                            </div>
                            {/* END Right Section */}
                        </div>

                        {/* Mobile Navigation */}
                        <div className={`lg:hidden ${mobileNavOpen ? "" : "hidden"}`}>
                            <nav className="flex flex-col gap-2 border-t border-gray-200 py-4 dark:border-gray-700">
                                <a
                                    href="#"
                                    className="group flex items-center gap-2 rounded-lg border border-emerald-50 bg-emerald-50 px-3 py-2 text-sm font-semibold text-emerald-600 dark:border-transparent dark:bg-gray-700/75 dark:text-white"
                                >
                                    <svg
                                        className="hi-mini hi-home inline-block size-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    <span>Dashboard</span>
                                </a>
                                <a
                                    href="#"
                                    className="group flex items-center gap-2 rounded-lg border border-transparent px-3 py-2 text-sm font-medium text-gray-800 hover:bg-emerald-50 hover:text-emerald-600 active:border-emerald-100 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white dark:active:border-gray-600"
                                >
                                    <svg
                                        className="hi-mini hi-users inline-block size-5 opacity-25 group-hover:opacity-100"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
                                    </svg>
                                    <span>Customers</span>
                                </a>
                                <a
                                    href="#"
                                    className="group flex items-center gap-2 rounded-lg border border-transparent px-3 py-2 text-sm font-medium text-gray-800 hover:bg-emerald-50 hover:text-emerald-600 active:border-emerald-100 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white dark:active:border-gray-600"
                                >
                                    <svg
                                        className="hi-mini hi-briefcase inline-block size-5 opacity-25 group-hover:opacity-100"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M6 3.75A2.75 2.75 0 018.75 1h2.5A2.75 2.75 0 0114 3.75v.443c.572.055 1.14.122 1.706.2C17.053 4.582 18 5.75 18 7.07v3.469c0 1.126-.694 2.191-1.83 2.54-1.952.599-4.024.921-6.17.921s-4.219-.322-6.17-.921C2.694 12.73 2 11.665 2 10.539V7.07c0-1.321.947-2.489 2.294-2.676A41.047 41.047 0 016 4.193V3.75zm6.5 0v.325a41.622 41.622 0 00-5 0V3.75c0-.69.56-1.25 1.25-1.25h2.5c.69 0 1.25.56 1.25 1.25zM10 10a1 1 0 00-1 1v.01a1 1 0 001 1h.01a1 1 0 001-1V11a1 1 0 00-1-1H10z"
                                            clipRule="evenodd"
                                        />
                                        <path d="M3 15.055v-.684c.126.053.255.1.39.142 2.092.642 4.313.987 6.61.987 2.297 0 4.518-.345 6.61-.987.135-.041.264-.089.39-.142v.684c0 1.347-.985 2.53-2.363 2.686a41.454 41.454 0 01-9.274 0C3.985 17.585 3 16.402 3 15.055z" />
                                    </svg>
                                    <span>Projects</span>
                                </a>
                                <a
                                    href="#"
                                    className="group flex items-center gap-2 rounded-lg border border-transparent px-3 py-2 text-sm font-medium text-gray-800 hover:bg-emerald-50 hover:text-emerald-600 active:border-emerald-100 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white dark:active:border-gray-600"
                                >
                                    <svg
                                        className="hi-mini hi-chart-bar inline-block size-5 opacity-25 group-hover:opacity-100"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path d="M15.5 2A1.5 1.5 0 0014 3.5v13a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0016.5 2h-1zM9.5 6A1.5 1.5 0 008 7.5v9A1.5 1.5 0 009.5 18h1a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0010.5 6h-1zM3.5 10A1.5 1.5 0 002 11.5v5A1.5 1.5 0 003.5 18h1A1.5 1.5 0 006 16.5v-5A1.5 1.5 0 004.5 10h-1z" />
                                    </svg>
                                    <span>Sales</span>
                                </a>
                            </nav>
                        </div>
                        {/* END Mobile Navigation */}
                    </div>
                </header>
                {/* END Page Header */}

                {/* Page Content */}
                <main id="page-content" className="flex max-w-full flex-auto flex-col">
                    {/* Page Section */}
                    <div className="container mx-auto p-4 lg:p-8 xl:max-w-7xl">
                        <Outlet context={userProfile}/>
                    </div>
                    {/* END Page Section */}
                </main>
                {/* END Page Content */}

                {/* Page Footer */}
                <footer
                    id="page-footer"
                    className="flex flex-none items-center bg-white dark:bg-gray-800"
                >
                    <div className="container mx-auto flex flex-col px-4 text-center text-sm md:flex-row md:justify-between md:text-left lg:px-8 xl:max-w-7xl">
                        <div className="inline-flex items-center justify-center pb-4 pt-1 md:pt-4">
                            <span>Crafted with</span>
                            <svg
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                className="hi-solid hi-heart mx-1 inline-block size-4 text-red-600"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span>
                {" "}
                                by{" "}
                                <a
                                    href="https://mygardenpal.com"
                                    className="font-medium text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300"
                                    target="_blank"
                                >
                  MyGardenPal
                </a> © 2024
              </span>
                        </div>
                    </div>
                </footer>
                {/* END Page Footer */}
            </div>
            {/* END Page Container */}
        </>
    );
}

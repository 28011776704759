export default function PricingToTheSide() {
    return (
        <>
            {/* Pricing Section: To The Side */}
            <div className="bg-white dark:bg-gray-900 dark:text-gray-100">
                <div className="container mx-auto flex flex-col gap-10 px-4 py-16 lg:flex-row lg:items-center lg:justify-between lg:gap-16 lg:px-8 lg:py-32 xl:max-w-7xl">
                    {/* Heading */}
                    <div className="text-center lg:text-left">
                        <div className="mb-1 text-sm font-bold uppercase tracking-wider text-emerald-600 dark:text-emerald-500">
                            Pick your plan
                        </div>
                        <h2 className="mb-4 text-4xl font-black text-black dark:text-white">
                            From vision to life
                        </h2>
                        <h3 className="text-xl font-medium leading-relaxed text-gray-700 dark:text-gray-300">
                            We've gathered all the necessary information about your garden. Please choose a plan that best meets your needs to ensure the highest quality output from our skilled garden architects and bring your garden vision to life.
                        </h3>
                    </div>
                    {/* END Heading */}

                    {/* Pricing Plans */}
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:w-3/5 lg:flex-none lg:gap-8">
                        {/* Solo Plan */}
                        <div className="flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition hover:border-gray-400 dark:border-gray-700/75 dark:bg-gray-900 dark:hover:border-gray-600">
                            <div className="grow p-6 text-center">
                <span className="mb-4 inline-block rounded-full bg-emerald-100/75 px-3 py-1 text-sm font-semibold text-emerald-700 dark:bg-emerald-900/50 dark:text-emerald-200">
                  Solo
                </span>
                                <div className="mb-1">
                                    <span className="text-4xl font-extrabold">$99</span>
                                </div>
                                <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                                    Only Plant Plan
                                </p>
                            </div>
                            <div className="flex justify-center bg-gray-50 p-6 dark:bg-gray-700/25">
                                <ul className="space-y-4 text-sm lg:text-base">
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-check-circle inline-block size-6 text-emerald-600 dark:text-emerald-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span>
                      Personalized Plant Plan
                    </span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-check-circle inline-block size-6 text-emerald-600 dark:text-emerald-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span>
                      <strong>60</strong> Days Delivery
                    </span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-x-circle inline-block size-6 text-rose-600 dark:text-rose-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <del>
                      <span>
                        <strong>Care</strong> Plan
                      </span>
                                        </del>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-x-circle inline-block size-6 text-rose-600 dark:text-rose-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <del>
                                        <span>
                      <strong>Urgent</strong> Disease Help
                    </span>
                                        </del>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-x-circle inline-block size-6 text-rose-600 dark:text-rose-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <del>
                      <span>
                        <strong>Recommended</strong> Garden Elements
                      </span>
                                        </del>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-x-circle inline-block size-6 text-rose-600 dark:text-rose-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <del>
                      <span>
                        <strong>24/7</strong> PRO Support
                      </span>
                                        </del>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-6">
                                <a
                                    href="#"
                                    className="flex items-center justify-center gap-2 rounded-lg border border-gray-700 bg-gray-700 px-6 py-3 font-semibold leading-6 text-white hover:border-gray-600 hover:bg-gray-600 hover:text-white focus:ring focus:ring-gray-400/50 active:border-gray-700 active:bg-gray-700 dark:focus:ring-gray-400/90"
                                >
                                    Get Started
                                </a>
                            </div>
                        </div>
                        {/* END Solo Plan */}

                        {/* Pro Plan */}
                        <div
                            className="relative flex flex-col overflow-hidden rounded-lg border border-emerald-300 bg-white transition hover:border-emerald-500 dark:border-emerald-700/75 dark:bg-gray-900 dark:hover:border-emerald-600">
                            <div
                                className="absolute right-0 top-0 flex size-12 items-center justify-center text-emerald-600">
                                <svg
                                    className="hi-solid hi-bookmark inline-block size-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </div>
                            <div className="grow p-6 text-center">
                <span className="mb-4 inline-block rounded-full bg-emerald-100/75 px-3 py-1 text-sm font-semibold text-emerald-700 dark:bg-emerald-900/50 dark:text-emerald-200">
                  Pro
                </span>
                                <div className="mb-1">
                                    <span className="text-4xl font-extrabold">$29</span>
                                    <span className="font-semibold text-gray-700 dark:text-gray-400/75">
                    /month
                  </span>
                                </div>
                                <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                                    Plant Plan & Continuous Assistance
                                </p>
                            </div>
                            <div className="flex justify-center bg-emerald-50 p-6 text-emerald-950 dark:bg-emerald-900/25 dark:text-emerald-50">
                                <ul className="space-y-4 text-sm lg:text-base">
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-check-circle inline-block size-6 text-emerald-600 dark:text-emerald-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span>
                      Personalized Plant Plan
                    </span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-check-circle inline-block size-6 text-emerald-600 dark:text-emerald-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span>
                      <strong>30</strong> Days Delivery
                    </span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-check-circle inline-block size-6 text-emerald-600 dark:text-emerald-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span>
                      <strong>Care</strong> Plan
                    </span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-check-circle inline-block size-6 text-emerald-600 dark:text-emerald-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span>
                      <strong>Urgent</strong> Disease Help
                    </span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-check-circle inline-block size-6 text-emerald-600 dark:text-emerald-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span>
                      <strong>Recommended</strong> Garden Elements
                    </span>
                                    </li>
                                    <li className="flex items-center gap-2">
                                        <svg
                                            className="hi-outline hi-check-circle inline-block size-6 text-emerald-600 dark:text-emerald-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <span>
                      <strong>24/7</strong> PRO Support
                    </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-6">
                                <a
                                    href="#"
                                    className="flex items-center justify-center gap-2 rounded-lg border border-emerald-700 bg-emerald-700 px-6 py-3 font-semibold leading-6 text-white hover:border-emerald-600 hover:bg-emerald-600 hover:text-white focus:ring focus:ring-emerald-400/50 active:border-emerald-700 active:bg-emerald-700 dark:focus:ring-emerald-400/90"
                                >
                                    Get Started
                                </a>
                            </div>
                        </div>
                        {/* END Pro Plan */}
                    </div>
                    {/* END Pricing Plans */}
                </div>
            </div>
            {/* END Pricing Section: To The Side */}
        </>
    );
}

import React from "react";
import Layout from "./components/Layout";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import UserProfile from "./components/admin/UserProfile";
import Home from "./components/Home";
import AdminLayout from "./components/admin/AdminLayout";
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { AuthContextProvider } from "./context/AuthContext";
import Welcome from "./components/admin/Welcome";
import InteractiveGuide from "./components/admin/InteractiveGuide";
import PricingToTheSide from "./components/admin/PricingToTheSide";
import VerifyEmail from "./components/VerifyEmail";
import PasswordReset from "./components/PasswordReset";

const App: React.FC = () => {
    return (
        <AuthContextProvider>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="signin" element={<Signin />} />
                    <Route path="signup" element={<Signup />} />
                    <Route path="verify-email" element={<VerifyEmail />} />
                    <Route path="password-reset" element={<PasswordReset />} />
                </Route>
                <Route path="/admin" element={<ProtectedRoute><AdminLayout /></ProtectedRoute>}>
                    <Route index element={<Welcome />} />
                    <Route path="interactive-guide" element={<InteractiveGuide />} />
                    <Route path="pricing" element={<PricingToTheSide />} />
                    <Route path="profile" element={<UserProfile />} />
                </Route>
            </Routes>
        </AuthContextProvider>
    );
};

export default App;

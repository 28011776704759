import React from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {FormData} from "../../../types/types";

type BasicGardenInformationsProps = {
    nextStep: () => void;
    gardenData: FormData;
    setGardenData: (data: FormData) => void;
};

export default function Step1({ nextStep, gardenData, setGardenData }: BasicGardenInformationsProps) {
    const { register, handleSubmit } = useForm<FormData>({
        defaultValues: gardenData
    });

    const onSubmit: SubmitHandler<FormData> = (data) => {
        setGardenData(data);
        nextStep();
    };

    return (
        <>
            <div className='bg-white mt-8 py-16 rounded-lg flex justify-center'>
                <div className='w-1/2'>
                    <h2 className="text-2xl font-bold text-center">Help us understand your garden better</h2>
                    {/* Form Elements: Inputs */}
                    <form className="mt-8 space-y-12 dark:text-gray-100" onSubmit={handleSubmit(onSubmit)}>

                        {/* Text Input */}
                        <div className="space-y-4 flex flex-col items-center text-center">
                            <label htmlFor="firstname" className="font-medium text-center">
                                Garden Size
                            </label>
                            <input
                                type="number"
                                id="size"
                                //name="gardensize"
                                placeholder="Enter the size of your garden"
                                className="block w-1/2 text-center rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-emerald-500 focus:ring focus:ring-emerald-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-emerald-500"
                                {...register("size")}
                            />
                        </div>

                        {/* Form Radio Groups: Simple Horizontal */}
                        <div className="space-y-2 dark:text-gray-100">
                            <div className="font-medium text-center">Garden Measurement</div>
                            <div className="flex flex-wrap justify-center items-center gap-4 pt-2">
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group0_a"
                                        value="sq"
                                        {...register("measurement")}
                                        defaultChecked={gardenData.measurement === 'sq'}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Square Feet (sq)
            </span>
                                </label>
                                <label className="group relative flex">
                                    <input
                                        type="radio"
                                        className="peer absolute left-0 top-0 appearance-none opacity-0"
                                        id="radio_group0_b"
                                        //name="radio_group0bb"
                                        value="m2"
                                        {...register("measurement")}
                                        defaultChecked={gardenData.measurement === 'm2'}
                                    />
                                    <span
                                        className="relative flex grow cursor-pointer items-center gap-2 rounded-lg border border-gray-200 px-4 py-2.5 text-sm font-medium group-hover:border-emerald-500 peer-checked:border-emerald-400 peer-checked:bg-emerald-50 peer-focus:ring peer-focus:ring-emerald-100 dark:border-gray-700 dark:group-hover:border-emerald-600 dark:peer-checked:border-emerald-600 dark:peer-checked:bg-emerald-800/10 dark:peer-focus:ring-emerald-900">
              Meters (m²)
            </span>
                                </label>
                            </div>
                        </div>
                        {/* END Form Radio Groups: Simple Horizontal */}

                        <div className='flex justify-center'>
                            {/* Button (normal) */}
                            <input
                                type="submit"
                                className="inline-flex cursor-pointer items-center justify-center gap-2 rounded-lg border border-emerald-700 bg-emerald-700 px-4 py-2 font-semibold leading-6 text-white hover:border-emerald-600 hover:bg-emerald-600 hover:text-white focus:ring focus:ring-emerald-400/50 active:border-emerald-700 active:bg-emerald-700 dark:focus:ring-emerald-400/90"
                                value="Continue to Basic Informations"
                            />
                            {/* END Button (normal) */}
                        </div>

                        {/* END Text Input */}
                    </form>
                    {/* END Form Elements: Inputs */}
                </div>
            </div>
        </>
    );
}

import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";
import { useOutletContext } from 'react-router-dom';
import { UserProfile as Profile } from '../../types/userProfile';

const UserProfile = () => {
    const userProfileData = useOutletContext<Profile>();
    const navigate = useNavigate();
    console.log(userProfileData);

    const logoutUser = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        await signOut(auth);
        navigate("/");
    }

    return(
        <>
            {/* Page Partials: User Profile: With Cards */}
            <div className="space-y-4 lg:space-y-8 dark:text-gray-100">
                {/* Banner */}
                <div className="rounded-lg bg-gradient-to-r from-emerald-400 to-purple-500 p-6 text-center">
                    <div className="mb-5 inline-block">
                        <div className="rounded-full bg-white/50 p-2">
                            <img
                                src="https://cdn.tailkit.com/media/placeholders/avatar-c_GmwfHBDzk-160x160.jpg"
                                alt="User Avatar"
                                className="inline-block size-20 rounded-full"
                            />
                        </div>
                    </div>
                    <h3 className="text-xl font-bold text-white"></h3>
                </div>
                {/* END Banner */}

                {/* Vital Info */}
                <div className="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
                    <div className="grow p-5 md:flex lg:p-8">
                        <div className="mb-5 border-b border-gray-200 md:mb-0 md:w-1/3 md:flex-none md:border-0 dark:border-gray-700">
                            <h3 className="mb-1 flex items-center justify-start gap-2 font-semibold">
                                <svg
                                    className="hi-mini hi-user-circle inline-block size-5 text-emerald-500"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-5.5-2.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zM10 12a5.99 5.99 0 00-4.793 2.39A6.483 6.483 0 0010 16.5a6.483 6.483 0 004.793-2.11A5.99 5.99 0 0010 12z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span>User Profile</span>
                            </h3>
                            <p className="mb-5 text-sm text-gray-500 dark:text-gray-400">
                                Your account’s vital info. Only your username and photo will be
                                publicly visible.
                            </p>
                        </div>
                        <div className="md:w-2/3 md:pl-24">
                            <form className="space-y-6 xl:w-2/3">
                                <div className="space-y-1">
                                    <label htmlFor="name" className="font-medium">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="John Doe"
                                        className="block w-full cursor-not-allowed rounded border border-gray-200 bg-gray-100 px-3 py-2 leading-6 focus:border-teal-500 focus:ring focus:ring-teal-500/50 dark:border-gray-500 dark:bg-gray-500"
                                        defaultValue={userProfileData.displayName}
                                        disabled
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label htmlFor="email" className="font-medium">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="block w-full cursor-not-allowed rounded border border-gray-200 bg-gray-100 px-3 py-2 leading-6 focus:border-teal-500 focus:ring focus:ring-teal-500/50 dark:border-gray-500 dark:bg-gray-500"
                                        defaultValue={userProfileData.email}
                                        disabled
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* END Vital Info */}
            </div>
        </>
    )
}

export default UserProfile
import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';

export default function PasswordReset() {
    const [email, setEmail] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const handlePasswordReset = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsProcessing(true);

        try {
            await sendPasswordResetEmail(auth, email);
            toast.success('Password reset email sent. Please check your inbox.', {
                duration: 6000,
            });
            setEmail('');
            setIsProcessing(false);
        } catch (error) {
            console.error(error);
            toast.error('Failed to send password reset email. Please try again.');
            setIsProcessing(false);
        }
    };

    return (
        <>
            <Toaster />
            <div
                id="page-container"
                className="mx-auto flex min-h-dvh w-full min-w-[320px] flex-col bg-gray-100 dark:bg-gray-900 dark:text-gray-100"
            >
                <main id="page-content" className="flex max-w-full flex-auto flex-col">
                    <div className="relative mx-auto flex min-h-dvh w-full max-w-10xl items-center justify-center overflow-hidden p-4 lg:p-8">
                        <section className="w-full max-w-xl py-6">
                            <header className="mb-10 text-center">
                                <h1 className="mb-2 inline-flex items-center gap-2 text-2xl font-bold">
                                    <svg
                                        className="hi-mini hi-cube-transparent inline-block size-5 text-emerald-600 dark:text-emerald-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M9.638 1.093a.75.75 0 01.724 0l2 1.104a.75.75 0 11-.724 1.313L10 2.607l-1.638.903a.75.75 0 11-.724-1.313l2-1.104zM5.403 4.287a.75.75 0 01-.295 1.019l-.805.444.805.444a.75.75 0 01-.724 1.314L3.5 7.02v.73a.75.75 0 01-1.5 0v-2a.75.75 0 01.388-.657l1.996-1.1a.75.75 0 011.019.294zm9.194 0a.75.75 0 011.02-.295l1.995 1.101A.75.75 0 0118 5.75v2a.75.75 0 01-1.5 0v-.73l-.884.488a.75.75 0 11-.724-1.314l.806-.444-.806-.444a.75.75 0 01-.295-1.02zM7.343 8.284a.75.75 0 011.02-.294L10 8.893l1.638-.903a.75.75 0 11.724 1.313l-1.612.89v1.557a.75.75 0 01-1.5 0v-1.557l-1.612-.89a.75.75 0 01-.295-1.019zM2.75 11.5a.75.75 0 01.75.75v1.557l1.608.887a.75.75 0 01-.724 1.314l-1.996-1.101A.75.75 0 012 14.25v-2a.75.75 0 01.75-.75zm14.5 0a.75.75 0 01.75.75v2a.75.75 0 01-.388.657l-1.996 1.1a.75.75 0 11-.724-1.313l1.608-.887V12.25a.75.75 0 01.75-.75zm-7.25 4a.75.75 0 01.75.75v.73l.888-.49a.75.75 0 01.724 1.313l-2 1.104a.75.75 0 01-.724 0l-2-1.104a.75.75 0 11.724-1.313l.888.49v-.73a.75.75 0 01.75-.75z"
                                        />
                                    </svg>
                                    <span>MyGardenPal</span>
                                </h1>
                                <h2 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                    Don't worry, we've got your back!
                                </h2>
                            </header>

                            <div
                                className="flex flex-col overflow-hidden rounded-lg bg-white shadow-sm dark:bg-gray-800 dark:text-gray-100">
                                <div className="grow p-5 md:px-16 md:py-12">
                                    <form className="space-y-6" onSubmit={handlePasswordReset}>
                                        <div className="space-y-1">
                                            <label htmlFor="email" className="text-sm font-medium">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="Enter your email"
                                                className="block w-full rounded-lg border border-gray-200 px-5 py-3 leading-6 placeholder-gray-500 focus:border-emerald-500 focus:ring focus:ring-emerald-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-emerald-500"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <button
                                                type="submit"
                                                className="inline-flex w-full items-center justify-center gap-2 rounded-lg border border-emerald-700 bg-emerald-700 px-6 py-3 font-semibold leading-6 text-white hover:border-emerald-600 hover:bg-emerald-600 hover:text-white focus:ring focus:ring-emerald-400/50 active:border-emerald-700 active:bg-emerald-700 dark:focus:ring-emerald-400/90"
                                            >
                                                {isProcessing ?
                                                    <>
                                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                                             xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10"
                                                                    stroke="currentColor" stroke-width="4"></circle>
                                                            <path className="opacity-75" fill="currentColor"
                                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        <span>Validating...</span>
                                                    </>
                                                    : (
                                                        <>
                                                            <span>Password reset</span>
                                                        </>
                                                    )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="grow bg-gray-50 p-5 text-center text-sm md:px-16 dark:bg-gray-700/50">
                                    <Link
                                        to="/signin"
                                        className="font-medium text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300"
                                    >
                                        Return to Sign In
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-6 text-center text-sm text-gray-500 dark:text-gray-400">
                                Powered by&nbsp;
                                <Link
                                    to="/"
                                    className="font-medium text-emerald-600 hover:text-emerald-400 dark:text-emerald-400 dark:hover:text-emerald-300"
                                >
                                    MyGardenPal
                                </Link>
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        </>
    );
}

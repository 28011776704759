import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    User as FirebaseUser,
    UserCredential
} from 'firebase/auth';
import { auth } from '../firebase';

interface AuthContextProps {
    user: FirebaseUser | null;
    createUser: (email: string, password: string) => Promise<UserCredential>;
    loading: boolean;
    signIn: (email: string, password: string) => Promise<UserCredential>;
    logout: () => Promise<void>;
}

const UserContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthContextProviderProps {
    children: ReactNode;
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const [user, setUser] = useState<FirebaseUser | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const createUser = (email: string, password: string) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const signIn = (email: string, password: string) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const logout = () => {
        return signOut(auth);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            console.log(currentUser);
            setUser(currentUser);
            // Když se stránka obnoví, Firebase musí nejprve ověřit aktuálního uživatele, což může nějakou dobu trvat. Pokud se ProtectedRoute zobrazuje dříve, než se Firebase dokončí ověřování, user může být stále null.
            // Jedním ze způsobů, jak tento problém vyřešit, je přidat stav načítání (loading) do vašeho kontextu autentizace a čekat, dokud se autentizace neprovede. Zde je, jak můžete upravit svůj kód:
            setLoading(false);
        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <UserContext.Provider value={{ createUser, user, loading, logout, signIn }}>
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = (): AuthContextProps => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('UserAuth must be used within an AuthContextProvider');
    }
    return context;
};

import React, { useState } from 'react';
import StepsCircleswithLabels from "./StepsCircleswithLabels";
import BasicGardenInformations from "./BasicGardenInformations";
import PlantPreferences from "./PlantPreferences";
import ReviewAndSubmit from "./ReviewAndSubmit";
import {FormData} from "../../types/types";
import ProgressBar from "./ProgressBar";
import Step1 from "./guide/Step1";
import Divider from "./Divider";

export default function InteractiveGuide() {

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 4;

    const [gardenData, setGardenData] = useState<FormData>({
        measurement: '',
        size: 0,
        sunlight: '',
        soilType: '',
        climate: '',
        plantTypes: [],
        colorPreferences: [],
        specialRequirements: []
    });

    const nextStep = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const submitData = () => {
        // zde můžete implementovat logiku pro odeslání dat, například pomocí API volání
        console.log('Odesílání dat:', gardenData);
        alert('Data byla odeslána');
    };

    return (
        <>
            <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
            {currentStep === 1 &&
                <Step1
                    nextStep={nextStep}
                    gardenData={gardenData}
                    setGardenData={setGardenData}
                />
            }
            {currentStep === 2 &&
                <BasicGardenInformations
                    nextStep={nextStep}
                    prevStep={prevStep}
                    gardenData={gardenData}
                    setGardenData={setGardenData}
                />
            }
            {currentStep === 3 &&
                <PlantPreferences
                    nextStep={nextStep}
                    prevStep={prevStep}
                    gardenData={gardenData}
                    setGardenData={setGardenData}
                />
            }
            {currentStep === 4 && (
                <ReviewAndSubmit
                    prevStep={prevStep}
                    submitData={submitData}
                    gardenData={gardenData}
                />
            )}
        </>
    )
};